<template>
  <!-- Desc: Bidding Strategy View at the camapign level -->
  <div class="card min-height-card u-spacing-p-l">
    <loader
      v-show="campaignInfo.status === 'loading'"
      class="fill--parent"
      :loading="campaignInfo.status === 'loading'"
      :color="'#3684bb'"
    />
    <div :class="!valuesAreChanged && 'u-spacing-pb-xxl'">
      <div v-if="selectedRadio">
        <div class="u-spacing-pb-m u-font-size-5 u-font-weight-700">
          Goals Cannot be modified
        </div>
        <div>
          <span class="u-font-size-5 u-color-grey-lighter u-font-weight-600">
            {{ selectedRadioConfig.title }} :
          </span>
          <span class="u-font-size-5">
            {{ selectedRadioConfig.desc }}
          </span>
        </div>
      </div>
      <div v-else>
        <div class="u-spacing-pb-m u-font-size-5 u-font-weight-600">
          Goals are not provided by Instacart through the APIs
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from '@/components/basic/loader';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';

export default {
  components: {
    loader,
    footerButtons
  },
  props: {
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      selectedRadio: null,
      selectedRadioConfig: null,
      getState: null,
      tabConfig: null,
      objective: null
    };
  },
  computed: {
    campaignInfo() {
      return (this.getState && this.$store.getters[this.getState]) || {};
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.selectedRadio = this.$store.getters[this.getState]?.data?.objective;
    this.selectedRadioConfig = this.tabMetadata.config.types.find(
      (obj) => obj.value === this.selectedRadio
    );
  }
};
</script>
<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>
